<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>BTP</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                prepend-icon="mdi-account"
                name="login"
                v-model="username"
                label="E-mail"
                :rules="emailRules"
                type="text"
              ></v-text-field>
              <v-text-field
                id="password"
                v-model="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Hasło"
                type="password"
                :rules="passwordRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" @click="login()"
              >Zaloguj</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const axios = require("axios");
import Swal from "sweetalert2";

export default {
  name: "LoginPage",
  data: () => ({
    valid: true,
    password: "",
    username: "",
    emailRules: [
      (v) => !!v || "Podaj Poprawny Adres E-mail",
      (v) => /.+@.+\..+/.test(v) || "Podaj Poprawny Adres E-mail",
    ],
    passwordRules: [(v) => !!v || "Hasło Jest Wymagane"],
  }),
  props: {
    source: String,
  },
  methods: {
    async login() {
      try {
        if (!this.password.trim().length) {
          Swal.fire("Podaj Hasło", "error");
          return;
        }
        if (!this.username.trim().length) {
          Swal.fire("Podaj E-mail", "error");
          return;
        }
        const user = await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "admin-auth/login",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            email: this.username,
            password: this.password,
          },
        });
        localStorage.setItem("user", true);
        localStorage.setItem("token", user.data.access_token);
        window.location.href = "/";
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response?.statusText || "UnCaptured Err", "error");
        localStorage.setItem("user", false);
        localStorage.setItem("token", null);
      }
    },
  },
};
</script>
