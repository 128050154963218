import Vue from 'vue'
import VueRouter from 'vue-router'
import CompanyPage from "../pages/CompanyPage.vue";
import AdminsPage from "../pages/AdminsPage.vue";
import SubCompanyPage from "../pages/SubCompanyPage.vue";
import DepartamentsPage from "../pages/DepartamentsPage.vue";
import BankAccountsPage from "../pages/BankAccountsPage.vue";
import DriverPage from "../pages/DriverPage.vue";
import DriverDetailsPage from "../pages/DriverDetailsPage.vue";
import FleetPage from "../pages/FleetPage.vue";
import FuelCardsPage from "../pages/FuelCardsPage.vue";
import DocumentTemplatesPage from "../pages/DocumentTemplatesPage.vue";
import LoginPage from "../pages/LoginPage.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/fleet",
    name: "FleetPage",
    component: FleetPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/fuel-cards",
    name: "FuelCardsPage",
    component: FuelCardsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/company",
    name: "CompanyPage",
    component: CompanyPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/drivers",
    name: "DriverPage",
    component: DriverPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/driver-details",
    name: "DriverDetailsPage",
    component: DriverDetailsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/subcompanies",
    name: "SubCompanyPage",
    component: SubCompanyPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/admins",
    name: "AdminsPage",
    component: AdminsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/departaments",
    name: "DepartamentsPage",
    component: DepartamentsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/document-templates",
    name: "DocumentTemplatesPage",
    component: DocumentTemplatesPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/bank-accounts",
    name: "BankAccountsPage",
    component: BankAccountsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "AdminsPage",
    component: AdminsPage,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if user is logged in
    if (!isLoggedIn()) {
      // If user is not logged in, redirect to login page
      next("/login");
    } else {
      // If user is logged in, proceed to the route
      next();
    }
  } else {
    // If the route doesn't require authentication, proceed as usual
    next();
  }
});

// Function to check if user is logged in (You need to implement this)
function isLoggedIn() {
  // Example: Check if user is logged in by checking authentication token or session
  return localStorage.getItem("token") !== null; // Assuming you store a token in localStorage
}
export default router;