<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <!-- Trigger Button for Dialog -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="documentTemplates"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Szablony Dokumentów</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn class="primary" @click="dialog = true">Dodaj</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.deleteDocumentTemplate="{ item }">
            <v-btn class="error" @click="deleteDocumentTemplate(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialog for Uploading Document -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload Document</span>
        </v-card-title>

        <v-card-text>
          <!-- Form for Uploading Document -->
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <!-- Select Company -->
            <v-row>
              <v-col cols="12" md="12">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    label="Nazwa Dokumentu"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>
                <v-select
                  v-model="formData.companyId"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Select Company"
                  required
                />
              </v-col>

              <!-- Select Subcompany -->
              <v-col cols="12" md="12">
                <v-select
                  v-model="formData.subCompanyId"
                  :items="subCompanies"
                  item-text="name"
                  item-value="id"
                  label="Select Subcompany"
                  :disabled="!formData.departmentId"
                />
              </v-col>

              <!-- Select Department -->
              <v-col cols="12" md="12">
                <v-select
                  v-model="formData.departmentId"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Select Department"
                  :disabled="!formData.companyId"
                />
              </v-col>

              <!-- File Upload -->
              <v-col cols="12" md="12">
                <v-file-input
                  v-model="formData.file"
                  label="Upload Document"
                  accept=".docx"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- Actions: Submit and Close -->
        <v-card-actions>
          <v-btn color="blue darken-1" @click="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" @click="submitForm" :disabled="!valid"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      documentTemplates: [],
      headers: [
        {
          text: "Nazwa Dokumentu",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Firma",
          align: "start",
          sortable: false,
          value: "companyName",
        },
        {
          text: "Powiązane Firmy",
          align: "start",
          sortable: false,
          value: "subcompanyNames",
        },
        {
          text: "Oddziały",
          align: "start",
          sortable: false,
          value: "departamentNames",
        },
        { text: "", value: "deleteBankAccount", sortable: false },
      ],
      loading: false,
      dialog: false, // Controls visibility of the dialog
      valid: false, // Form validity
      formData: {
        name: null,
        companyId: null,
        departmentId: null,
        subCompanyId: null,
        file: null,
      },
      companies: [], // List of companies
      departments: [], // List of departments based on selected company
      subCompanies: [], // List of subcompanies based on selected department
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => /.+@.+\..+/.test(value) || "Invalid email.",
        password: (value) =>
          value.length >= 6 || "Password must be at least 6 characters.",
      },
    };
  },
  methods: {
    // Fetch the list of companies (from an API or predefined)
    fetchCompanies() {
      // Mock example: You would fetch the companies from your API
      this.companies = [
        { id: "company1", name: "Company 1" },
        { id: "company2", name: "Company 2" },
      ];
    },

    // Fetch departments based on the selected company
    fetchDepartments() {
      if (this.formData.companyId) {
        // Mock example: Replace with API call to get departments for the selected company
        if (this.formData.companyId === "company1") {
          this.departments = [
            { id: "department1", name: "Department 1" },
            { id: "department2", name: "Department 2" },
          ];
        } else {
          this.departments = [
            { id: "department3", name: "Department 3" },
            { id: "department4", name: "Department 4" },
          ];
        }
      }
    },

    // Fetch subcompanies based on the selected department
    fetchSubCompanies() {
      if (this.formData.departmentId) {
        // Mock example: Replace with API call to get subcompanies for the selected department
        if (this.formData.departmentId === "department1") {
          this.subCompanies = [
            { id: "subcompany1", name: "Subcompany 1" },
            { id: "subcompany2", name: "Subcompany 2" },
          ];
        } else {
          this.subCompanies = [
            { id: "subcompany3", name: "Subcompany 3" },
            { id: "subcompany4", name: "Subcompany 4" },
          ];
        }
      }
    },

    // Handle form submission (upload document)
    submitForm() {
      // Perform file validation and API call to upload the document
      if (!this.formData.file) {
        this.$toast.error("Please upload a document");
        return;
      }

      const formData = new FormData();
      formData.append("file", this.formData.file);
      formData.append("companyId", this.formData.companyId);
      formData.append("departmentId", this.formData.departmentId);
      formData.append("subCompanyId", this.formData.subCompanyId);
    },
  },
  watch: {
    // Watch for changes in the company and department selection
    "formData.companyId": function (newVal) {
      if (newVal) {
        this.fetchDepartments();
      } else {
        this.departments = [];
        this.subCompanies = [];
      }
    },
    "formData.departmentId": function (newVal) {
      if (newVal) {
        this.fetchSubCompanies();
      } else {
        this.subCompanies = [];
      }
    },
  },
  mounted() {
    // Fetch companies when the component is mounted
    this.fetchCompanies();
  },
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
