<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <!-- Trigger Button for Dialog -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="drivers"
          class="elevation-1"
          :loading="loading"
          :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Kierowcy</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                @click="
                  (dialog = true),
                    (action = 'Tworzenie'),
                    (currentDriverId = null)
                "
                >Dodaj</v-btn
              >
            </v-toolbar>
            <div>
              <v-text-field
                v-model="search"
                label="Szukaj"
                class="mx-4"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn class="warning" @click="infoDriver(item.id)"
              ><v-icon small class="pt-1">mdi-pencil</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteDriver="{ item }">
            <v-btn class="error" @click="deleteDriver(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Form Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Driver Form</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <v-row>
              <!-- First Name -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.firstName"
                  label="First Name"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Last Name -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.lastName"
                  label="Last Name"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Email -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.email"
                  label="Email"
                  :rules="[rules.required, rules.email]"
                  required
                />
              </v-col>

              <!-- Password -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.password"
                  label="Password"
                  type="password"
                  :rules="[rules.required, rules.password]"
                  required
                />
              </v-col>
              <!-- Password -->
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="formData.phone"
                  label="Phone"
                  type="phone"
                  :rules="[rules.required]"
                  required
                />
              </v-col>
              <!-- Company Select (UUID) -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="formData.companyId"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Select Company"
                  :rules="[rules.required]"
                  required
                />
              </v-col>
              <!-- Subcompany IDs (Multi-select) -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="formData.subCompanyIds"
                  :items="subCompanies"
                  item-text="name"
                  item-value="id"
                  label="Select Subcompanies"
                  :rules="[rules.required]"
                  required
                />
              </v-col>
              <!-- Department IDs (Multi-select) -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="formData.departmentIds"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Select Departments"
                  :rules="[rules.required]"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Cancel Button -->
          <v-btn text color="secondary" @click="closeDialog">Cancel</v-btn>
          <!-- Submit Button -->
          <v-btn color="primary" @click="submitForm" :disabled="!valid">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios");
import Swal from "sweetalert2";
export default {
  data() {
    return {
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      totalItems: 0,
      drivers: [],
      headers: [
        {
          text: "Mail",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Firma",
          align: "start",
          sortable: false,
          value: "companyName",
        },
        {
          text: "Powiązane Firmy",
          align: "start",
          sortable: false,
          value: "subcompanyNames",
        },
        {
          text: "Oddziały",
          align: "start",
          sortable: false,
          value: "departamentNames",
        },
        { text: "", value: "details", sortable: false },
        { text: "", value: "deleteDriver", sortable: false },
      ],
      loading: false,
      dialog: false,
      valid: false,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: "",
        companyId: null,
        departmentIds: [],
        subCompanyIds: [],
      },
      companies: [],
      departments: [],
      subCompanies: [],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => /.+@.+\..+/.test(value) || "Invalid email.",
        password: (value) =>
          value.length >= 6 || "Password must be at least 6 characters.",
      },
    };
  },
  watch: {
    "formData.subCompanyIds": function (newVal) {
      if (newVal) {
        this.getDepartmentsSelect();
      } else {
        this.departments = [];
      }
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    search: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async getSubcompanySelect() {
      await this.toggleLoading(true);
      try {
        const subCompanies = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `sub-company`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.subCompanies = subCompanies.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async infoDriver(id) {
      location.href = `/driver-details?id=${id}`;
    },
    async deleteDriver(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "drivers/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
    async getDepartmentsSelect() {
      await this.toggleLoading(true);
      try {
        const departaments = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `departments`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            subCompanyIds: this.formData.subCompanies,
          },
        });
        this.departments = departaments.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async submitForm() {
      // Validate the form
      if (this.$refs.form.validate()) {
        console.log("Driver Form Submitted:", this.formData);
        try {
          await this.toggleLoading(true);
          await axios({
            method: "post",
            url: process.env.VUE_APP_ROOT_API + "drivers",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {
              ...this.formData,
            },
          });
          this.closeDialog();
          await this.toggleLoading(false);
          await this.initialize();
        } catch (e) {
          await this.toggleLoading(false);
          console.log(e);
          Swal.fire("Błąd", e.response.data.message.toString(), "error");
        }
      }
    },
      
    async getCompanySelect() {
      await this.toggleLoading(true);
      try {
        const companies = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `company`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.companies = companies.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    closeDialog() {
      this.dialog = false;
      this.currentDriverId = undefined;
      this.$refs.form.resetValidation(); // Reset form validation
    },
    async initialize() {
      await this.getCompanySelect();
      await this.getSubcompanySelect();
      this.loading = true;
      if (this.search.trim().length > 0) {
        this.options.page = 1;
      }
      try {
        const drivers = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            `drivers?take=${this.options.itemsPerPage}&skip=${this.options.page}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.drivers = drivers.data.data.result.map((p) => {
          return {
            id: p.id,
            email: p.email,
            companyName: p.company.name,
            subcompanyNames: p.departments
              .map((it) => it.department.name)
              .toString(),
            departamentNames: p.subCompanies
              .map((it) => it.subcompany.name)
              .toString(),
          };
        });
        this.totalItems = drivers.data.data.count;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
/* Optional: Add any custom styles here */
</style>
