<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <!-- Trigger Button for Dialog -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="companyAdmins"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Administratorzy</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                @click="
                  (dialog = true),
                    (action = 'Tworzenie'),
                    (currentCompanyAdminId = null)
                "
                >Dodaj</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn class="warning" @click="infoCompanyAdmin(item.id)"
              ><v-icon small class="pt-1">mdi-pencil</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteCompanyAdmin="{ item }">
            <v-btn class="error" @click="deleteCompanyAdmin(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Form Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Company Admin Form</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <v-row>
              <!-- First Name -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.firstName"
                  label="First Name"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Last Name -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.lastName"
                  label="Last Name"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Email -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.email"
                  label="Email"
                  :rules="[rules.required, rules.email]"
                  required
                />
              </v-col>

              <!-- Password -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.password"
                  label="Password"
                  type="password"
                />
              </v-col>

              <!-- Password -->
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="formData.phone"
                  label="Phone"
                  type="phone"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Company Select (UUID) -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="formData.companyId"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Select Company"
                  :rules="[rules.required]"
                  required
                />
              </v-col>
              <!-- Subcompany IDs (Multi-select) -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="formData.subCompanyIds"
                  :items="subCompanies"
                  item-text="name"
                  item-value="id"
                  label="Select Subcompanies"
                  multiple
                  :rules="[rules.required]"
                  required
                />
              </v-col>
              <!-- Department IDs (Multi-select) -->
              <v-col cols="12" md="12">
                <v-select
                  v-model="formData.departmentIds"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Select Departments"
                  multiple
                  :rules="[rules.required]"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Cancel Button -->
          <v-btn text color="secondary" @click="closeDialog">Cancel</v-btn>
          <!-- Submit Button -->
          <v-btn color="primary" @click="submitForm" :disabled="!valid">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
export default {
  data() {
    return {
      companyAdmins: [],
      headers: [
        {
          text: "Mail",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Firma",
          align: "start",
          sortable: false,
          value: "companyName",
        },
        {
          text: "Powiązane Firmy",
          align: "start",
          sortable: false,
          value: "subcompanyNames",
        },
        {
          text: "Oddziały",
          align: "start",
          sortable: false,
          value: "departamentNames",
        },
        { text: "", value: "details", sortable: false },
        { text: "", value: "deleteCompanyAdmin", sortable: false },
      ],
      loading: false,
      dialog: false,
      valid: false,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: "",
        companyId: null,
        departmentIds: [],
        subCompanyIds: [],
      },
      companies: [],
      departments: [],
      subCompanies: [],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => /.+@.+\..+/.test(value) || "Invalid email.",
      },
    };
  },
  async created() {
    await this.initialize();
  },
  watch: {
    "formData.subCompanyIds": function (newVal) {
      if (newVal) {
        this.getDepartmentsSelect();
      } else {
        this.departments = [];
      }
    },
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async getCompanySelect() {
      await this.toggleLoading(true);
      try {
        const companies = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `company`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.companies = companies.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async deleteCompanyAdmin(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "company-admins/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
    async getSubcompanySelect() {
      await this.toggleLoading(true);
      try {
        const subCompanies = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `sub-company`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.subCompanies = subCompanies.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async getDepartmentsSelect() {
      await this.toggleLoading(true);
      try {
        const departaments = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `departments`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            subCompanyIds: this.formData.subCompanies,
          },
        });
        this.departments = departaments.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async submitForm() {
      // Validate the form
      if (this.$refs.form.validate()) {
        console.log("Company Admin Form Submitted:", this.formData);
        try {
          await this.toggleLoading(true);
          if (this.currentCompanyAdminId) {
            await axios({
              method: "patch",
              url:
                process.env.VUE_APP_ROOT_API +
                "company-admins/" +
                this.currentCompanyAdminId,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: {
                ...this.formData,
                password:
                  this.formData.password?.trim().length > 0
                    ? this.formData.password
                    : null,
              },
            });
          } else {
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "company-admins",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: {
                ...this.formData,
              },
            });
          }

          this.closeDialog();
          await this.toggleLoading(false);
          await this.initialize();
        } catch (e) {
          await this.toggleLoading(false);
          console.log(e);
          Swal.fire("Błąd", e.response.data.message.toString(), "error");
        }
      }
    },
    closeDialog() {
      this.dialog = false;
      this.currentCompanyAdminId = undefined;
      this.$refs.form.resetValidation(); // Reset form validation
    },
    async infoCompanyAdmin(id) {
      await this.toggleLoading(true);
      try {
        const dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "company-admins/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.formData = dep.data.data;
        this.formData.departmentIds = dep.data.data.departments.map(
          (it) => it.department.name
        );
        this.formData.subCompanyIds = dep.data.data.subcompanies.map(
          (it) => it.subcompany.name
        );

        this.currentCompanyAdminId = dep.data.data.id;
        this.dialog = true;
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    async initialize() {
      this.loading = true;
      await this.getCompanySelect();
      await this.getSubcompanySelect();
      try {
        const users = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `company-admins`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.companyAdmins = users.data.data.map((user) => {
          return {
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            email: user.email,
            id: user.id,
            companyName: user.company.name,
            subcompanyNames: user.subcompanies
              .map((it) => it.subcompany.name)
              .toString(),
            departamentNames: user.departments
              .map((it) => it.department.name)
              .toString(),
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
/* Optional: Add any custom styles here */
</style>
