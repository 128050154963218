<!-- eslint-disable vue/valid-v-slot -->

<template>
  <v-container>
    <!-- Trigger Button for Dialog -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="fuelCards"
          class="elevation-1"
          :loading="loading"
          :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Karty Paliwowe</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                @click="
                  (dialog = true),
                    (action = 'Tworzenie'),
                    (currentFuelCardId = null)
                "
                >Dodaj</v-btn
              >
            </v-toolbar>
            <div>
              <v-text-field
                v-model="search"
                label="Szukaj"
                class="mx-4"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn class="warning" @click="infoFuelCard(item.id)"
              ><v-icon small class="pt-1">mdi-pencil</v-icon></v-btn
            >
          </template>
          <template v-slot:item.historyFuelCard="{ item }">
            <v-btn class="info" @click="historyFuelCard(item.id)"
              ><v-icon small class="pt-1">mdi-history</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteFuelCard="{ item }">
            <v-btn class="error" @click="deleteFuelCard(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- History Dialog -->
    <v-dialog v-model="historyDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Fuel Card History - {{ selectedCard }}</span
          >
        </v-card-title>

        <v-card-text>
          <!-- Timeline -->
          <v-timeline dense>
            <v-timeline-item
              v-for="(event, index) in selectedCardHistory"
              :key="index"
              :color="event.color || 'blue'"
              :icon="event.icon || 'mdi-calendar'"
            >
              <template>
                <strong>{{ event.createdAt }}</strong>
              </template>
              <v-card>
                <v-card-text style="white-space: break-spaces;">{{ event.description }}</v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Karta Paliwowa</span>
        </v-card-title>

        <v-card-text>
          <!-- Form -->
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <v-row>
              <!-- Number -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.number"
                  label="Number"
                  outlined
                  required
                  :rules="[validateRequired]"
                />
              </v-col>

              <!-- Provider -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.provider"
                  label="Provider"
                  outlined
                  required
                  :rules="[validateRequired]"
                />
              </v-col>

              <!-- Car ID (Select) -->
              <v-col cols="12">
                <v-select
                  v-model="formData.carId"
                  :items="cars"
                  item-text="name"
                  item-value="id"
                  label="Car"
                  outlined
                  :rules="[validateOptionalUUID]"
                  return-object
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- Actions -->
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="submitForm"
            :disabled="!valid"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from "dayjs";
export default {
  data() {
    return {
      historyDialog: false,
      selectedCard:"",
      selectedCardHistory: [],
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        {
          text: "Numer",
          align: "start",
          sortable: false,
          value: "number",
        },
        {
          text: "Dostawca",
          align: "start",
          sortable: false,
          value: "provider",
        },
        {
          text: "Pojazd",
          align: "start",
          sortable: false,
          value: "car",
        },

        { text: "", value: "details", sortable: false },
        { text: "", value: "historyFuelCard", sortable: false },
        { text: "", value: "deleteFuelCard", sortable: false },
      ],
      fuelCards: [],
      totalItems: 0,
      dialog: false, // Controls dialog visibility
      valid: false, // Form validity
      currentCardId: null,
      formData: {
        number: "", // Required
        provider: "", // Required
        carId: null, // Selected Car (Object: { id, name })
      },
      cars: [],
    };
  },
 watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    search: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    validateUUID(value) {
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return !value || uuidRegex.test(value) || "Invalid UUID format";
    },
    validateOptionalUUID(value) {
      return value === null || this.validateUUID(value.id || value);
    },
    validateRequired(value) {
      return !!value || "This field is required";
    },
    async submitForm() {
      if (!this.valid) return;
      try {
        await this.toggleLoading(true);
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "fuel-cards",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            ...this.formData,
            carId: this.formData.carId.id,
            id: this.currentCardId || undefined,
          },
        });
        this.closeDialog();
        await this.toggleLoading(false);
        await this.initialize();
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    closeDialog() {
      this.dialog = false;
      this.currentCardId = undefined;
      this.$refs.form.resetValidation(); // Reset form validation
    },
    async infoFuelCard(id) {
      await this.toggleLoading(true);
      try {
        const card = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "fuel-cards/details/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.formData = card.data.data;
        this.currentCardId = card.data.data.id;
        this.formData.carId = card.data.data.carId;
        this.dialog = true;
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    async historyFuelCard(id) {
      await this.toggleLoading(true);
      try {
            const card = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "fuel-cards/details/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selectedCard = card.data.data.number;
        const history = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "fuel-cards/history/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selectedCardHistory = history.data.data.map((it) => {
          return {
            id: it.id,
            description: it.description,
            createdAt: dayjs().format("DD-MM-YYYY HH:mm"),
          };
        });
        this.historyDialog = true;
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    async initialize() {
      this.loading = true;
      await this.getCarsSelect();
      if (this.search.trim().length > 0) {
        this.options.page = 1;
      }
      try {
        const cards = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            `fuel-cards?take=${this.options.itemsPerPage}&skip=${this.options.page}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.fuelCards = cards.data.data.result.map((card) => {
          return {
            number: card.number,
            provider: card.provider,
            car: card.car
              ? `${card.car.make}-${card.car.model} ${card.car.plate}`
              : "",
            id: card.id,
          };
        });
        this.totalItems = cards.data.data.count;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
    async getCarsSelect() {
      await this.toggleLoading(true);
      try {
        const cars = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `cars?take=10&skip=1`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.cars = cars.data.data.result.map((it) => {
          return {
            id: it.id,
            name: `${it.make}-${it.model} ${it.plate}`,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async deleteFuelCard(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "fuel-cards/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
