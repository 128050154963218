<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <!-- Trigger Button for Dialog -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="subCompanies"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Powiązane Firmy</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                @click="
                  (dialog = true),
                    (action = 'Tworzenie'),
                    (currentSubCompanyId = null)
                "
                >Dodaj</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn class="warning" @click="infoCompany(item.id)"
              ><v-icon small class="pt-1">mdi-pencil</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteSubCompany="{ item }">
            <v-btn class="error" @click="deleteSubCompany(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Form Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">User Form</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <v-row>
              <!-- Name Field -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.name"
                  label="Name"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Address Line 1 -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.addressLine1"
                  label="Address Line 1"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Address Line 2 (Optional) -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.addressLine2"
                  label="Address Line 2"
                />
              </v-col>

              <!-- Zip Code -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.zipCode"
                  label="Zip Code"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- City -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.city"
                  label="City"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Country -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.country"
                  label="Country"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <!-- Tax Code (Optional) -->
              <v-col cols="12" md="6">
                <v-text-field v-model="formData.taxCode" label="Tax Code" />
              </v-col>

              <!-- Company Select (UUID) -->
              <!-- <v-col cols="12" md="6">
                <v-select
                  v-model="formData.companyId"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Select Company"
                  :rules="[rules.required]"
                  required
                />
              </v-col> -->
              <!-- Automatic Payouts Enabled -->
              <v-col cols="12" md="6">
                <v-switch
                  v-model="formData.automaticPayoutsEnabled"
                  label="Automatic Payouts Enabled"
                  disabled
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Cancel Button -->
          <v-btn text color="secondary" @click="closeDialog">Cancel</v-btn>
          <!-- Submit Button -->
          <v-btn color="primary" @click="submitForm" :disabled="!valid">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
export default {
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      action: "",
      currentSubCompanyId: null,
      subCompanies: [],
      headers: [
        {
          text: "Nazwa",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Miasto",
          align: "start",
          sortable: false,
          value: "city",
        },
        { text: "", value: "details", sortable: false },
        { text: "", value: "deleteSubCompany", sortable: false },
      ],
      formData: {
        name: "",
        addressLine1: "",
        addressLine2: "",
        zipCode: "",
        city: "",
        country: "",
        taxCode: "",
        automaticPayoutsEnabled: false,
      },
      companies: [
        // Example data - replace with your API data or state
        { id: "uuid-1234", name: "Company A" },
        { id: "uuid-5678", name: "Company B" },
        { id: "uuid-9012", name: "Company C" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async submitForm() {
      // Check if form is valid
      if (this.$refs.form.validate()) {
        console.log("Form Submitted:", this.formData);
        try {
          await this.toggleLoading(true);
          await axios({
            method: "post",
            url: process.env.VUE_APP_ROOT_API + "sub-company",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {
              ...this.formData,
              id: this.currentSubCompanyId || undefined,
            },
          });
          this.closeDialog();
          await this.toggleLoading(false);
          await this.initialize();
        } catch (e) {
          await this.toggleLoading(false);
          console.log(e);
          Swal.fire("Błąd", e.response.data.message.toString(), "error");
        }
      }
    },
    async infoCompany(id) {
      await this.toggleLoading(true);
      try {
        const dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "sub-company/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.formData = dep.data.data;
        this.currentSubCompanyId = dep.data.data.id;
        this.dialog = true;
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    async deleteSubCompany(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "sub-company/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
    async initialize() {
      this.loading = true;
      try {
        const subCompanies = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `sub-company`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.subCompanies = subCompanies.data.data.map((user) => {
          return {
            name: user.name,
            city: user.city,
            id: user.id,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },

    closeDialog() {
      this.dialog = false;
      this.currentSubCompanyId = undefined;
      this.$refs.form.resetValidation(); // Reset form validation
    },
  },
};
</script>
