<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <!-- Trigger Button for Dialog -->
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="cars"
          class="elevation-1"
          :loading="loading"
          :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Flota</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                @click="
                  (dialog = true), (action = 'Tworzenie'), (currentCarId = null)
                "
                >Dodaj</v-btn
              >
            </v-toolbar>
            <div>
              <v-text-field
                v-model="search"
                label="Szukaj"
                class="mx-4"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn class="warning" @click="infoCar(item.id)"
              ><v-icon small class="pt-1">mdi-pencil</v-icon></v-btn
            >
          </template>
          <template v-slot:item.historyCar="{ item }">
            <v-btn class="info" @click="historyCar(item.id)"
              ><v-icon small class="pt-1">mdi-history</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteCar="{ item }">
            <v-btn class="error" @click="deleteCar(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Dialog -->
    <v-dialog v-model="dialogHistory" fullscreen persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ plate }} History</span>
        </v-card-title>

        <v-card-text>
          <!-- Timeline -->
          <v-timeline dense>
            <v-timeline-item
              v-for="(event, index) in carHistory"
              :key="index"
              :color="event.color || 'blue'"
              :icon="event.icon || 'mdi-calendar'"
            >
              <template v-slot:opposite>
                <strong>{{ event.date }}</strong>
              </template>
              <v-card>
                <v-card-title>{{ event.description }}</v-card-title>
                <v-card-subtitle>{{ event.type }}</v-card-subtitle>
                <v-card-text>
                  <div v-if="event.metadata && event.metadata.length">
                    <h4>Metadata:</h4>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(meta, i) in event.metadata" :key="i">
                          <td>{{ meta.name }}</td>
                          <td>{{ meta.value }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                  <div>
                    <v-btn
                      v-if="event.image"
                      @click="downloadImage(event.image)"
                      color="info"
                      >Pobierz Plik</v-btn
                    >
                    <v-btn @click="deleteCarHistory(event.id)" color="error"
                      >Usuń</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>

        <v-spacer />
        <v-card-title>
          <span class="headline">Add History Record</span>
        </v-card-title>
        <!-- Form to Add History Record -->
        <v-card-text>
          <v-form
            ref="form"
            v-model="carHistoryValid"
            @submit.prevent="addHistory"
          >
            <v-row>
              <!-- Description -->
              <v-col cols="12">
                <v-text-field
                  v-model="newHistory.description"
                  label="Description"
                  outlined
                  required
                  :rules="[validateRequired]"
                />
              </v-col>

              <!-- History Record Type -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="newHistory.historyRecordType"
                  :items="historyRecordTypes"
                  label="Record Type"
                  outlined
                  required
                  :rules="[validateRequired]"
                  @change="onHistoryRecordTypeChange"
                />
              </v-col>

              <!-- Image Upload -->
              <v-col cols="12" md="6">
                <v-file-input
                  v-model="newHistory.image"
                  label="Upload Image"
                  outlined
                  accept="image/*"
                />
              </v-col>

              <!-- Custom Metadata -->
              <v-col cols="12">
                <h4>Additional Information</h4>
                <v-row
                  v-for="(field, index) in predefinedMetadataFields"
                  :key="index"
                >
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="predefinedMetadata[field.name]"
                      :label="field.label"
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-divider class="my-3" />
                <h4>Custom Fields</h4>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="metadataField.name"
                      label="Metadata Name"
                      outlined
                    />
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="metadataField.value"
                      label="Metadata Value"
                      outlined
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn color="primary" @click="addMetadata">Add</v-btn>
                  </v-col>
                </v-row>
                <v-simple-table v-if="newHistory.metadata.length">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(meta, i) in newHistory.metadata" :key="i">
                      <td>{{ meta.name }}</td>
                      <td>{{ meta.value }}</td>
                      <td>
                        <v-btn icon small @click="removeMetadata(i)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- Actions -->
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialogHistory = false"
            >Close</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            :disabled="!carHistoryValid"
            @click="addHistory"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Add New Vehicle</span>
        </v-card-title>

        <v-card-text>
          <!-- Form -->
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <v-row>
              <!-- Make -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.make"
                  label="Make"
                  outlined
                  required
                />
              </v-col>

              <!-- Model -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.model"
                  label="Model"
                  outlined
                  required
                />
              </v-col>

              <!-- Color -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.color"
                  label="Color"
                  outlined
                  required
                />
              </v-col>

              <!-- Plate -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.plate"
                  label="Plate"
                  outlined
                  required
                />
              </v-col>

              <!-- Production Year -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.productionYear"
                  label="Production Year"
                  type="number"
                  outlined
                  required
                />
              </v-col>

              <!-- Last Service Date -->
              <v-col cols="12" md="6">
                <v-menu
                  v-model="menu.lastService"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.lastService"
                      label="Last Service"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    />
                  </template>
                  <v-date-picker
                    v-model="formData.lastService"
                    @input="menu.lastService = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- Insurance Valid To -->
              <v-col cols="12" md="6">
                <v-menu
                  v-model="menu.insuranceValidTo"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.insuranceValidTo"
                      label="Insurance Valid To"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    />
                  </template>
                  <v-date-picker
                    v-model="formData.insuranceValidTo"
                    @input="menu.insuranceValidTo = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- Inspection Valid To -->
              <v-col cols="12" md="6">
                <v-menu
                  v-model="menu.inspectionValidTo"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.inspectionValidTo"
                      label="Inspection Valid To"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    />
                  </template>
                  <v-date-picker
                    v-model="formData.inspectionValidTo"
                    @input="menu.inspectionValidTo = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- Wrap Type -->
              <v-col cols="12" md="6">
                <v-select
                  v-model="formData.wrapType"
                  :items="wrapTypes"
                  label="Wrap Type"
                  outlined
                  required
                />
              </v-col>

              <!-- Advance -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.advance"
                  label="Advance (€)"
                  type="number"
                  outlined
                  required
                />
              </v-col>

              <!-- Rent Price -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.rentPrice"
                  label="Rent Price (€)"
                  type="number"
                  outlined
                  required
                />
              </v-col>

              <!-- Odometer -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.odometer"
                  label="Odometer (km)"
                  type="number"
                  outlined
                  required
                />
              </v-col>

              <!-- Color -->
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="formData.sideNumber"
                  label="Side Number"
                  outlined
                  required
                />
              </v-col>

              <!-- Car ID (Select) -->
              <v-col cols="12">
                <v-select
                  v-model="formData.cardId"
                  :items="fuelCards"
                  item-text="name"
                  item-value="id"
                  label="Fuel Card"
                  outlined
                  :rules="[validateOptionalUUID]"
                  return-object
                />
              </v-col>

              <!-- Car ID (Select) -->
              <v-col cols="12">
                <v-select
                  v-model="formData.driverId"
                  :items="drivers"
                  item-text="name"
                  item-value="id"
                  label="Driver"
                  outlined
                  :rules="[validateOptionalUUID]"
                  return-object
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- Actions -->
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="submitForm"
            :disabled="!valid"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from "dayjs";
export default {
  data() {
    return {
      currentCarId: null,
      totalItems: 0,
      dialog: false, // Dialog visibility
      valid: false, // Form validity
      carHistoryValid: false,
      dialogHistory: false,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cars: [],
      fuelCards: [],
      drivers: [],
      headers: [
        {
          text: "Marka",
          align: "start",
          sortable: false,
          value: "make",
        },
        {
          text: "Model",
          align: "start",
          sortable: false,
          value: "model",
        },
        {
          text: "Rejestracja",
          align: "start",
          sortable: false,
          value: "plate",
        },

        { text: "", value: "details", sortable: false },
        { text: "", value: "historyCar", sortable: false },
        { text: "", value: "deleteCar", sortable: false },
      ],
      carHistory: [
        // Example history data
        {
          date: "2024-11-01",
          description: "Oil change",
          type: "Maintenance",
          metadata: [
            { name: "Cost", value: "€50" },
            { name: "Service Center", value: "AutoFix" },
          ],
          image: null,
          color: "green",
          icon: "mdi-wrench",
        },
      ],
      newHistory: {
        description: "",
        historyRecordType: "",
        image: null,
        metadata: [],
      },
      metadataField: {
        name: "",
        value: "",
      },
      loading: false,
      predefinedMetadataFields: [], // Dynamic fields
      predefinedMetadata: {}, // Dynamic field values
      historyRecordTypes: ["MILEAGE", "CRASH", "SERVICE", "OHER"], // Example record types
      metadataTemplates: {
        Maintenance: [
          { name: "cost", label: "Cost (€)" },
          { name: "serviceCenter", label: "Service Center" },
        ],
        Repair: [
          { name: "repairCost", label: "Repair Cost (€)" },
          { name: "repairShop", label: "Repair Shop" },
        ],
        Incident: [
          { name: "incidentDate", label: "Incident Date" },
          { name: "reportedToInsurance", label: "Reported to Insurance" },
        ],
      },
      menu: {
        lastService: false,
        insuranceValidTo: false,
        inspectionValidTo: false,
      },
      formData: {
        make: "",
        model: "",
        color: "",
        plate: "",
        productionYear: null,
        lastService: null,
        insuranceValidTo: null,
        inspectionValidTo: null,
        wrapType: null,
        advance: null,
        rentPrice: null,
        odometer: null,
        sideNumber: null,
        cardId: null,
        driverId: null,
      },
      plate: "",
      wrapTypes: ["BOLT", "UBER", "CUSTOM", "FREENOW", "OWN"], // Wrap Type options
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    search: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  async created() {
    await this.getDriversSelect();
    await this.getFuelCards();
    await this.initialize();
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    validateOptionalUUID(value) {
      return value === null || this.validateUUID(value.id || value);
    },
    validateUUID(value) {
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return !value || uuidRegex.test(value) || "Invalid UUID format";
    },
    validateRequired(value) {
      return !!value || "This field is required";
    },
    onHistoryRecordTypeChange(type) {
      // Load predefined metadata fields based on the type
      this.predefinedMetadataFields = this.metadataTemplates[type] || [];
      this.predefinedMetadata = {};
    },
    addMetadata() {
      if (this.metadataField.name && this.metadataField.value) {
        this.newHistory.metadata.push({ ...this.metadataField });
        this.metadataField.name = "";
        this.metadataField.value = "";
      }
    },
    removeMetadata(index) {
      this.newHistory.metadata.splice(index, 1);
    },
    async addHistory() {
      if (!this.carHistoryValid) return;
      // Combine predefined metadata with additional metadata
      const combinedMetadata = [
        ...this.newHistory.metadata,
        ...Object.entries(this.predefinedMetadata).map(([key, value]) => ({
          name: key,
          value,
        })),
      ];
      const formData = new FormData();
      try {
        formData.append(
          "file",
          this.newHistory.image,
          this.newHistory.image.name
        );
      } catch (e) {
        console.log("Missing Image");
      }
      formData.append("carId", this.currentCarId);
      formData.append("metadata", JSON.stringify(combinedMetadata));
      formData.append("description", this.newHistory.description);
      formData.append("historyRecordType", this.newHistory.historyRecordType);
      await axios({
        method: "post",
        url: process.env.VUE_APP_ROOT_API + "cars-history",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      // Reset form
      this.newHistory = {
        description: "",
        historyRecordType: "",
        image: null,
        metadata: [],
      };
      this.predefinedMetadataFields = [];
      this.predefinedMetadata = {};
      await this.historyCar(this.currentCarId);
    },
    async historyCar(id) {
      await this.toggleLoading(true);
      try {
        const car = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "cars/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const carHistory = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "cars-history/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.plate = car.data.data.plate;
        this.currentCarId = id;
        this.carHistory = carHistory.data.data.map((it) => {
          return {
            id: it.id,
            date: dayjs(id.createdAt).format("YYYY-MM-DD HH:mm"), // Add current date
            description: it.description,
            type: this.newHistory.historyRecordType,
            metadata:
              typeof it.metadata === "string"
                ? JSON.parse(it.metadata)
                : it.metadata,
            image: it.files[0]?.id || null, // Image file object or path
            color: "blue", // Default color for new records
            icon: "mdi-plus",
          };
        });
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.dialogHistory = true;
    },
    async submitForm() {
      if (!this.valid) return;
      try {
        await this.toggleLoading(true);
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "cars",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            ...this.formData,
            driverId: this.formData.driverId.id,
            cardId: this.formData.cardId.id,
            advance: parseInt(this.formData.advance),
            rentPrice: parseInt(this.formData.rentPrice),
            odometer: parseInt(this.formData.odometer),
            productionYear: parseInt(this.formData.productionYear),
            id: this.currentCarId || undefined,
          },
        });
        this.closeDialog();
        await this.toggleLoading(false);
        await this.initialize();
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    closeDialog() {
      this.dialog = false;
      this.currentCarId = undefined;
      this.$refs.form.resetValidation(); // Reset form validation
    },
    async infoCar(id) {
      await this.toggleLoading(true);
      try {
        const car = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "cars/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.formData = car.data.data;
        this.currentCarId = car.data.data.id;
        this.formData.driverId =
          car.data.data.drivers.length > 0 ? car.data.data.drivers[0].id : null;
        this.formData.cardId =
          car.data.data.fuelCards.length > 0
            ? car.data.data.fuelCards[0].id
            : null;
        this.formData.inspectionValidTo = dayjs(
          car.data.data.inspectionValidTo
        ).format("YYYY-MM-DD");
        this.formData.insuranceValidTo = dayjs(
          car.data.data.insuranceValidTo
        ).format("YYYY-MM-DD");
        this.formData.lastService = dayjs(car.data.data.lastService).format(
          "YYYY-MM-DD"
        );
        this.dialog = true;
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    async getDriversSelect() {
      await this.toggleLoading(true);
      try {
        const drivers = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `drivers?take=100&skip=1`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.drivers = drivers.data.data.result.map((it) => {
          return {
            id: it.id,
            name: `${it.firstName} ${it.lastName}`,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async getFuelCards() {
      await this.toggleLoading(true);
      try {
        const fuelCards = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `fuel-cards?take=100&skip=1`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.fuelCards = fuelCards.data.data.result.map((it) => {
          return {
            id: it.id,
            name: `${it.provider}-${it.number}`,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async initialize() {
      this.loading = true;
      if (this.search.trim().length > 0) {
        this.options.page = 1;
      }
      try {
        const cars = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            `cars?take=${this.options.itemsPerPage}&skip=${this.options.page}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.cars = cars.data.data.result.map((car) => {
          return {
            model: car.model,
            make: car.make,
            plate: car.plate,
            id: car.id,
          };
        });
        this.totalItems = cars.data.data.count;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      this.loading = false;
    },
    async deleteCar(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "cars/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
    async downloadImage(id) {
      const file = await axios({
        method: "post",
        url: process.env.VUE_APP_ROOT_API + "aws/download-file/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        responseType: "blob",
      });
      console.log(file);
      let blob = new Blob([file.data], { type: file.data.type }),
        url = window.URL.createObjectURL(blob);
      window.open(url);
    },
    async deleteCarHistory(id) {
      Swal.fire({
        title: "Napewno usunąć?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios({
              method: "delete",
              url:
                process.env.VUE_APP_ROOT_API +
                "cars-history/" +
                this.currentCarId +
                "/" +
                id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.historyCar(this.currentCarId);
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.data.message.toString(), "error");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
