<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <!-- Tabs Navigation -->
    <v-tabs v-model="activeTab" background-color="primary" dark>
      <v-tab>General Information</v-tab>
      <v-tab>Documents</v-tab>
      <v-tab>Invoices</v-tab>
      <v-tab>Settlements History</v-tab>
      <v-tab>Imported Data</v-tab>
      <v-tab>Payouts</v-tab>
    </v-tabs>

    <!-- Tab Content -->
    <v-tabs-items v-model="activeTab">
      <!-- General Information Tab -->
      <v-tab-item>
        <v-card>
          <v-card-title>
            <span class="text-h6">General Information</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
              <v-row>
                <!-- First Name -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.firstName"
                    label="First Name"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>

                <!-- Last Name -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.lastName"
                    label="Last Name"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>

                <!-- Phone Number -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.phone"
                    label="Phone"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>

                <!-- Email -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.email"
                    label="Email"
                    :rules="[rules.required, rules.email]"
                    required
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="formData.password"
                    label="Password"
                    type="password"
                  />
                </v-col>
                <!-- City -->
                <v-col cols="12" md="12">
                  <v-text-field v-model="formData.city" label="City" />
                </v-col>

                <!-- Street Address 1 -->
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.streetAddress1"
                    label="Street Address 1"
                  />
                </v-col>

                <!-- Street Address 2 -->
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.streetAddress2"
                    label="Street Address 2"
                  />
                </v-col>

                <!-- License Number -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.licenseNumber"
                    label="License Number"
                  />
                </v-col>

                <!-- Bolt ID -->
                <v-col cols="12" md="6">
                  <v-text-field v-model="formData.boltId" label="Bolt ID" />
                </v-col>

                <!-- Uber ID -->
                <v-col cols="12" md="6">
                  <v-text-field v-model="formData.uberId" label="Uber ID" />
                </v-col>

                <!-- FreeNow ID -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.freeNowId"
                    label="FreeNow ID"
                  />
                </v-col>

                <!-- Payout Type -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="formData.payoutType"
                    :items="payoutTypes"
                    label="Payout Type"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>

                <!-- Payout Schedule -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="formData.payoutSchedule"
                    :items="payoutSchedules"
                    label="Payout Schedule"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>

                <!-- Cooperation Type -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="formData.cooperationType"
                    :items="cooperationTypes"
                    label="Cooperation Type"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>

                <!-- Zus Type -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="formData.zusType"
                    :items="zusTypes"
                    label="Zus Type"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>

                <!-- Account Number -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.accountNo"
                    label="Account Number"
                  />
                </v-col>

                <!-- Is Abroad Bank Account -->
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="formData.isAbroadBankAccount"
                    label="Is Abroad Bank Account"
                  />
                </v-col>

                <!-- Repossession Fields (Conditional) -->
                <v-col cols="12" v-if="formData.isReposession">
                  <v-divider></v-divider>
                  <v-text-field
                    v-model="formData.reposessionAccountNo"
                    label="Repossession Account No"
                  />
                </v-col>

                <v-col cols="12" v-if="formData.isReposession">
                  <v-text-field
                    v-model="formData.reposessionWireTitle"
                    label="Reposession Wire Title"
                  />
                </v-col>

                <v-col cols="12" v-if="formData.isReposession">
                  <v-text-field
                    v-model="formData.reposessionAddressLine1"
                    label="Reposession Address Line 1"
                  />
                </v-col>

                <v-col cols="12" v-if="formData.isReposession">
                  <v-text-field
                    v-model="formData.reposessionAddressLine2"
                    label="Reposession Address Line 2"
                  />
                </v-col>

                <!-- Is Ready for Settlements -->
                <v-col cols="12" md="6">
                  <v-checkbox
                    disabled
                    v-model="formData.isReadyForSettlements"
                    label="Is Ready for Settlements"
                  />
                </v-col>

                <!-- Missing Data -->
                <v-col cols="12" md="6">
                  <v-textarea
                    disabled
                    v-model="formData.missingData"
                    label="Missing Data"
                  />
                </v-col>

                <!-- Register Type -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="formData.registerType"
                    :items="registerTypes"
                    label="Register Type"
                    :rules="[rules.required]"
                    required
                  />
                </v-col>

                <!-- Car ID -->
              <!-- Car ID (Select) -->
              <v-col cols="6">
                <v-select
                  v-model="formData.carId"
                  :items="cars"
                  item-text="name"
                  item-value="id"
                  label="Car"
                  outlined
                  :rules="[validateOptionalUUID]"
                  return-object
                />
              </v-col>
                <!-- Subcompany IDs (Multi-select) -->
                <v-col cols="12" md="12">
                  <v-select
                    v-model="formData.subCompanyIds"
                    :items="subCompanies"
                    item-text="name"
                    item-value="id"
                    label="Select Subcompanies"
                    multiple
                  />
                </v-col>
                <!-- Department IDs (Multi-select) -->
                <v-col cols="12" md="12">
                  <v-select
                    v-model="formData.departmentIds"
                    :items="departments"
                    item-text="name"
                    item-value="id"
                    label="Select Departments"
                    multiple
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- Cancel Button -->
            <v-btn text color="secondary" @click="goBack">Go Back</v-btn>
            <!-- Submit Button -->
            <v-btn color="primary" @click="submitForm" :disabled="!valid">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <!-- Documents Tab -->
      <v-tab-item>
        <v-card>
          <v-card-title>
            <span class="text-h6">Documents</span>
          </v-card-title>
          <v-card-text>
            <p>Upload i wypelnione dokumenty</p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Invoices Tab -->
      <v-tab-item>
        <v-card>
          <v-card-title>
            <span class="text-h6">Invoices</span>
          </v-card-title>
          <v-card-text>
            <!-- Here, you can add a table or list to display invoices -->
            <v-data-table
              :headers="invoiceHeaders"
              :items="invoices"
              item-key="id"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="viewInvoice(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Settlements History Tab -->
      <v-tab-item>
        <v-card>
          <v-card-title>
            <span class="text-h6">Settlements History</span>
          </v-card-title>
          <v-card-text>
            <!-- Settlements History Table -->
            <v-data-table
              :headers="settlementHeaders"
              :items="settlements"
              item-key="id"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="viewSettlement(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Imported Data Tab -->
      <v-tab-item>
        <v-card>
          <v-card-title>
            <span class="text-h6">Imported Data</span>
          </v-card-title>
          <v-card-text>
            <!-- List or table of imported data -->
            <v-data-table
              :headers="importedDataHeaders"
              :items="importedData"
              item-key="id"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="viewImportedData(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Payouts Tab -->
      <v-tab-item>
        <v-card>
          <v-card-title>
            <span class="text-h6">Payouts</span>
          </v-card-title>
          <v-card-text>
            <!-- Payouts History -->
            <v-data-table
              :headers="payoutHeaders"
              :items="payouts"
              item-key="id"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn icon @click="viewPayout(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
const axios = require("axios");
export default {
  data() {
    return {
      activeTab: 0, // To manage which tab is active
      valid: false, // Form validation flag
      formData: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        city: "",
        streetAddress1: "",
        streetAddress2: "",
        licenseNumber: "",
        boltId: "",
        uberId: "",
        freeNowId: "",
        payoutType: "",
        payoutSchedule: "",
        cooperationType: "",
        zusType: "",
        accountNo: "",
        isAbroadBankAccount: false,
        isReposession: false,
        reposessionAccountNo: "",
        reposessionWireTitle: "",
        reposessionAddressLine1: "",
        reposessionAddressLine2: "",
        isReadyForSettlements: false,
        missingData: [],
        registerType: "",
        carId: "",
        subCompanyIds: [],
        departmentIds: [],
      },
      departments: [],
      subCompanies: [],
      payoutTypes: ["CASH", "WIRE"],
      payoutSchedules: ["DAILY", "WEEKLY", "EVERY_2_WEEKS", "MONTH"],
      cooperationTypes: [
        "B2B_VAT",
        "B2B_NO_VAT",
        "FIFTY_FIFTY",
        "RENT",
        "CONNECTION",
        "STUDENT",
      ],
      zusTypes: [
        "NOT_HIRED_L4",
        "NOT_HIRED_NO_L4",
        "HIRED_BELOW_WITH_L4",
        "HIRED_BELOW_NO_L4",
        "HIRED_ABOVE",
        "CONNECTION",
        "UNDER_26_NO_L4",
        "UNDER_26_L4",
      ],
      registerTypes: [
        "IM_DRIVER",
        "RECOMENDATION",
        "OTHER_PARTNER",
        "JOB_LOOKUP",
        "ADMIN",
      ],
      rules: {
        required: (value) => !!value || "This field is required",
        email: (value) =>
          /.+@.+\..+/.test(value) || "Enter a valid email address",
      },
      documents: {
        license: null,
        insurance: null,
        registration: null,
      },
      invoices: [
        { id: 1, number: "INV001", date: "2024-01-01", amount: 500 },
        { id: 2, number: "INV002", date: "2024-01-10", amount: 300 },
      ],
      settlements: [
        { id: 1, date: "2024-01-05", amount: 800, status: "Completed" },
        { id: 2, date: "2024-01-12", amount: 400, status: "Pending" },
      ],
      importedData: [
        { id: 1, type: "Import A", date: "2024-01-15" },
        { id: 2, type: "Import B", date: "2024-01-18" },
      ],
      payouts: [
        { id: 1, date: "2024-01-05", amount: 200 },
        { id: 2, date: "2024-01-10", amount: 350 },
      ],
      invoiceHeaders: [
        { text: "Invoice Number", align: "start", key: "number" },
        { text: "Date", align: "start", key: "date" },
        { text: "Amount", align: "start", key: "amount" },
        { text: "Actions", key: "actions", sortable: false },
      ],
      settlementHeaders: [
        { text: "Date", key: "date" },
        { text: "Amount", key: "amount" },
        { text: "Status", key: "status" },
        { text: "Actions", key: "actions", sortable: false },
      ],
      importedDataHeaders: [
        { text: "Type", key: "type" },
        { text: "Date", key: "date" },
        { text: "Actions", key: "actions", sortable: false },
      ],
      payoutHeaders: [
        { text: "Date", key: "date" },
        { text: "Amount", key: "amount" },
        { text: "Actions", key: "actions", sortable: false },
      ],
      currentDriverId: null,
          cars: [],
    };
  },
  watch: {
    "formData.subCompanyIds": function (newVal) {
      if (newVal) {
        this.getDepartmentsSelect();
      } else {
        this.departments = [];
      }
    },
  },
  async created() {
    const params = new URLSearchParams(document.location.search);
    const id = params.get("id");
    this.currentDriverId = id;
    await this.initialize(id);
  },
  methods: {
    goBack() {
      location.href = "/drivers";
    },
  validateUUID(value) {
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return !value || uuidRegex.test(value) || "Invalid UUID format";
    },
    validateOptionalUUID(value) {
      return value === null || this.validateUUID(value.id || value);
    },
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
async getCarsSelect() {
      await this.toggleLoading(true);
      try {
        const cars = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `cars?take=10&skip=1`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.cars = cars.data.data.result.map((it) => {
          return {
            id: it.id,
            name: `${it.make}-${it.model} ${it.plate}`,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async initialize(id) {
      await this.getSubcompanySelect();
            await this.getCarsSelect();

      await this.toggleLoading(true);
      try {
        const dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "drivers/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.formData = dep.data.data;
        this.formData.subCompanyIds = dep.data.data.subCompanies.map(
          (it) => it.subcompanyId
        );
        this.formData.departmentIds = dep.data.data.departments.map(
          (it) => it.departamentId
        );

        this.dialog = true;
        await this.toggleLoading(false);
      } catch (e) {
        await this.toggleLoading(false);
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
    },
    async getSubcompanySelect() {
      await this.toggleLoading(true);
      try {
        const subCompanies = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `sub-company`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.subCompanies = subCompanies.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async getDepartmentsSelect() {
      await this.toggleLoading(true);
      try {
        const departaments = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `departments`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            subCompanyIds: this.formData.subCompanyIds,
          },
        });
        this.departments = departaments.data.data.map((it) => {
          return {
            id: it.id,
            name: it.name,
          };
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.data.message.toString(), "error");
      }
      await this.toggleLoading(false);
    },
    async submitForm() {
      // Handle form submission logic
      if (this.$refs.form.validate()) {
        console.log("Form Submitted:", this.driverData);
        try {
          await axios({
            method: "patch",
            url:
              process.env.VUE_APP_ROOT_API + "drivers/" + this.currentDriverId,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {
              ...this.formData,
              carId: this.formData.carId.id,
              password:
                this.formData.password?.trim().length > 0
                  ? this.formData.password
                  : null,
            },
          });
          location.reload();
        } catch (e) {
          console.log(e);
          Swal.fire("Błąd", e.response.data.message.toString(), "error");
        }
      }
    },
    viewInvoice(item) {
      console.log("View Invoice:", item);
    },
    viewSettlement(item) {
      console.log("View Settlement:", item);
    },
    viewImportedData(item) {
      console.log("View Imported Data:", item);
    },
    viewPayout(item) {
      console.log("View Payout:", item);
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
